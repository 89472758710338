import { sanitizeNumber } from "./sanitizeNumber";

function parseCardNumber(cardNumberStr :  string) {
  let cardNumber = sanitizeNumber(cardNumberStr).toString();
  if (cardNumber.length === 16) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4");
  } else if(cardNumber.length === 15) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{3})/, "$1-$2-$3-$4");
  } else if (cardNumber.length === 14) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{2})/, "$1-$2-$3-$4");
  } else if (cardNumber.length === 13) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{1})/, "$1-$2-$3-$4");
  } else if(cardNumber.length === 12) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if(cardNumber.length === 11) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{3})/, "$1-$2-$3");
  } else if(cardNumber.length === 10) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{2})/, "$1-$2-$3");
  } else if(cardNumber.length === 9) {
    return cardNumber.replace(/(\d{4})(\d{4})(\d{1})/, "$1-$2-$3");
  } else if(cardNumber.length === 8) {
    return cardNumber.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else if(cardNumber.length === 7) {
    return cardNumber.replace(/(\d{4})(\d{3})/, "$1-$2");
  } else if(cardNumber.length === 6) {
    return cardNumber.replace(/(\d{4})(\d{2})/, "$1-$2");
  } else if(cardNumber.length === 5) {
    return cardNumber.replace(/(\d{4})(\d{1})/, "$1-$2");
  } else {
    return cardNumber;
  }
}

function parseCardExpiration(expiration : number) {
  let expirationStr = expiration.toString();
  if (expirationStr.length === 4) {
    return expirationStr.replace(/(\d{2})(\d{2})/, "$1/$2");
  } else if(expirationStr.length === 3) {
    return expirationStr.replace(/(\d{2})(\d{1})/, "$1/$2");
  } else {
    return expirationStr;
  }
}

function parsePhoneNumber(phoneNumber : string) {
  let phoneNumberStr = sanitizeNumber(phoneNumber).toString();
  if (phoneNumberStr.length === 10) {
    return phoneNumberStr.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if(phoneNumberStr.length === 9) {
    return phoneNumberStr.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
  } else if(phoneNumberStr.length === 8) {
    return phoneNumberStr.replace(/(\d{3})(\d{3})(\d{2})/, "$1-$2-$3");
  } else if(phoneNumberStr.length === 7) {
    return phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  } else if(phoneNumberStr.length === 6) {
    return phoneNumberStr.replace(/(\d{3})(\d{3})/, "$1-$2");
  } else if(phoneNumberStr.length === 5) {
    return phoneNumberStr.replace(/(\d{3})(\d{2})/, "$1-$2");
  } else if(phoneNumberStr.length === 4) {
    return phoneNumberStr.replace(/(\d{3})(\d{1})/, "$1-$2");
  } else if(phoneNumberStr.length === 3) {
    return phoneNumberStr.replace(/(\d{3})/, "$1");
  } else {
    return phoneNumberStr;
  }
}

function parsePercentage(percentage : number) {
  let percentageStr = percentage.toString();
  return percentageStr.replace(/\./, ',');
}

function parseDays(days : string | number) {
  days = sanitizeNumber(days);
  if (days === null) {
    return null;
  }
  return `${days} ${days === 1 ? 'day' : 'days'}`;
}


export { parseCardNumber , parseCardExpiration, parsePhoneNumber, parsePercentage, parseDays};




