import { ToolbarItem } from "../toolbar/ToolbarItem";

interface TeamToolbarProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TeamToolbar = ({ activeTab, onTabChange }: TeamToolbarProps) => {
  const tabs = [
    "Account",
    "Team",
    "Properties",
    "Usage",
    "Tickets",
    "Transactions",
    "Logs",
  ];

  return (
    <>
      <div className="tabs tabs-boxed border-[.5px] border-zinc-300 bg-white dark:border-back-dark dark:bg-card-dark">
        {tabs.map((tab) => (
          <ToolbarItem
            key={tab}
            title={tab}
            isActive={activeTab === tab}
            onClick={() => onTabChange(tab)}
          />
        ))}
      </div>
    </>
  );
};

export default TeamToolbar;
