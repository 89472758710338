import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class HubspotApi extends ApiTokenSupport {
  async getContact(hubspotID: number, properties?: string[]): Promise<any> {
    const resp = await axios.post<any>(
      `/api/hubspot/contact`,
      { hubspotID, properties },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateContact(hubspotID: number, properties: any): Promise<any> {
    const resp = await axios.post<any>(
      `/api/hubspot/contact/update`,
      { hubspotID, properties },
      this.withAuthorization()
    );
    return resp.data;
  }

  async syncContact(teamID: number): Promise<any> {
    const resp = await axios.post<any>(
      `/api/tasks/sync-hubspot-team`,
      { teamID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async searchHubspotForContact(query: Record<string, any>): Promise<any> {
    const resp = await axios.post<any>(
      `/api/hubspot/search`,
      { query },
      this.withAuthorization()
    );
    return resp.data;
  }
}
export default new HubspotApi();
