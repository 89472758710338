import React, { useEffect, useState } from "react";
import { timeUntil } from "src/helpers/parseEpochs";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import NewDemoModeTeamModal from "src/components/modals/NewDemoModeTeamModal";
import AdminApi from "src/api/admin";
import { Token } from "src/interfaces/token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faLink,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useAlert from "src/hooks/useAlert";
import CircularActionButton from "src/components/buttons/CircularActionButton";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";

const Demo = () => {
  const { isMobile } = useViewport();

  const { setAlert } = useAlert();

  const { openModalWith, setShowModal } = useModal();

  const [demoTokens, setDemoTokens] = useState<Token[]>([]);

  const fetchDemoTokens = async () => {
    const resp = await AdminApi.getDemoTokens();
    setDemoTokens(resp);
  };

  useEffect(() => {
    fetchDemoTokens();
  }, []);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handleNewDemoTeam = () => {
    openModalWith({
      title: "New Demo Mode Team",
      body: <NewDemoModeTeamModal fetchDemoTokens={fetchDemoTokens} />,
      hideButtons: true,
    });
  };

  const handleEmailToken = async (_token: Token) => {
    const { token, expireAt, email } = _token;
    try {
      await AdminApi.sendDemoEmail({ token, expireAt, email });
      setAlert({
        display: true,
        message: `Demo Mode Invitation sent to ${email}`,
        type: "success",
      });
    } catch (e) {
      setAlert({
        display: true,
        message: "An error occurred while sending Demo Mode Invitation email",
        type: "error",
      });
    }
  };

  const copyTextToClipboard = (str: string) => {
    return navigator.clipboard.writeText(str);
  };

  const handleDeleteSubmit = async (id: number) => {
    try {
      setShowModal(false);
      const result = await AdminApi.deleteToken(id);
      if (result) {
        setAlert({
          display: true,
          message: "Token Deleted",
          type: "success",
        });
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: "There was an error deleting this token",
        type: "error",
      });
    } finally {
      return fetchDemoTokens();
    }
  };

  const handleDelete = (token: Token) => {
    const { id, consumed, expireAt } = token;

    if (!isExpired(expireAt) && !consumed)
      openModalWith({
        title: "Delete Demo Mode Token",
        body: "Are you sure you want to delete this token? The user will no longer be able to use it to create a Demo Mode Account",
        onSubmit: () => handleDeleteSubmit(id),
        submitLabel: "Delete",
      });

    if (consumed) {
      openModalWith({
        title: "Delete Demo Mode Token",
        body: "Are you sure you want to delete this token? Please check this teams account page to ensure their account is no longer in Demo Mode before deleting.",
        onSubmit: () => handleDeleteSubmit(id),
        submitLabel: "Delete",
      });
    } else {
      openModalWith({
        title: "Delete Demo Mode Token",
        body: "Are you sure you want to delete this token?",
        onSubmit: () => handleDeleteSubmit(id),
        submitLabel: "Delete",
      });
    }
  };

  const isExpired = (timestamp: string) => {
    const timestampNumber = new Date(timestamp).getTime();
    const currentTime = Date.now();
    return currentTime > timestampNumber;
  };

  return (
    <>
      <div>
        <div>
          <button
            className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
            onClick={handleNewDemoTeam}
          >
            + New Demo Mode
          </button>
        </div>
        <table
          className={
            isMobile
              ? "table-compact block table w-full shadow-xl"
              : "block table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
              <th className="sticky top-[115px] max-w-full whitespace-normal rounded-tl-lg bg-card-light text-base dark:bg-card-dark lg:top-0">
                Email
              </th>
              <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0">
                Expires
              </th>
              <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0">
                Used
              </th>
              <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0 ">
                Link
              </th>
              <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0 ">
                Send Email
              </th>
              <th className="select-none rounded-tr-lg bg-card-light dark:bg-card-dark">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {demoTokens.length ? (
              demoTokens
                ?.sort(
                  (a: Token, b: Token) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((token: Token) => (
                  <tr
                    key={token.id}
                    className="my-0.5 bg-card-light text-base font-medium text-text-dark dark:bg-card-dark dark:text-text-light"
                  >
                    <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                      <button
                        className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                        onClick={() => copyTextToClipboard(token.email)}
                      >
                        {token.email}
                      </button>
                    </td>
                    <td
                      className={
                        isExpired(token.expireAt)
                          ? "bg-white text-error dark:bg-card-dark"
                          : "bg-white dark:bg-card-dark"
                      }
                    >
                      {timeUntil(token.expireAt)}
                    </td>
                    <td className="bg-card-light dark:bg-card-dark">
                      <FontAwesomeIcon
                        icon={token.consumed ? faCircleCheck : faCircleXmark}
                        className={
                          token.consumed ? "text-success" : "text-warning"
                        }
                      />
                    </td>
                    <td className="bg-card-light dark:bg-card-dark">
                      {!isExpired(token.expireAt) && !token.consumed && (
                        <button
                          className="btn-secondary btn-sm btn gap-1 text-text-light dark:text-text-dark"
                          onClick={() =>
                            copyTextToClipboard(
                              `${process.env.REACT_APP_FRONTEND_URL}/demo/${token.token}`
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faLink} className="" />
                          <div className="font-semibold">Link</div>
                        </button>
                      )}
                    </td>
                    <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                      {!isExpired(token.expireAt) && !token.consumed && (
                        <button
                          className="btn-accent btn-sm btn gap-1 text-text-light dark:text-text-dark"
                          onClick={() => handleEmailToken(token)}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} className="" />
                          <div className="font-semibold">Email</div>
                        </button>
                      )}
                    </td>
                    <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                      {!token.consumed && (
                        <CircularActionButton
                          icon={faTrash}
                          onClick={() => handleDelete(token)}
                        />
                      )}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={6}
                >
                  No Temporary Demo Teams yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Demo;
