function sanitizeNumber(cardNumberData : string | number) {
  let cardNumber = cardNumberData.toString();
  return parseInt(cardNumber.replace(/\D/g, ''));
}

function sanitizeFloat(cardNumberData : string | number) {
  let cardNumber = cardNumberData.toString();
  return parseInt(cardNumber.replace(/\D/g, ''));
}

export  { sanitizeNumber, sanitizeFloat };






