import { Tooltip as ReactTooltip } from "react-tooltip";

interface TooltipProps {
  id: string;
  content: any;
  delayShow?: number;
  delayHide?: number;
}

const Tooltip = ({ id, content, delayShow, delayHide }: TooltipProps) => {
  return (
    <>
      <ReactTooltip
        id={id}
        place="top"
        delayShow={delayShow}
        delayHide={delayHide}
        className="bg-black font-semibold text-text-light opacity-5 dark:bg-white dark:text-text-dark"
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
