import React, { useEffect, useState } from "react";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import Log from "src/interfaces/log";
import { TeamPageData } from "src/interfaces/TeamPageData";
import LogRow from "../log/LogRow";
import TableFooter from "../table/TableFooter";
import LoadingTable from "../loading/LoadingTable";

interface LogsTabProps {
  team: TeamPageData;
}

const LogsTab = ({ team }: LogsTabProps) => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setAlert } = useAlert();

  const fetchLogs = async () => {
    try {
      setIsLoading(true);
      const logs = await adminApi.getTeamLogs(team.team.id);
      setLogs(logs);
    } catch (error: any) {
      setAlert({
        display: true,
        message: error?.message ?? "Something went wrong, Please try later.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [team]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <LoadingTable label="Logs" />
        </div>
      ) : (
        <table className="table-compact block table w-full shadow-xl">
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
              <th className="sticky top-[115px] max-w-full whitespace-normal rounded-tl-lg bg-card-light text-base dark:bg-card-dark lg:top-0">
                Time
              </th>
              <th className="sticky top-[115px] bg-card-light text-base dark:bg-card-dark lg:top-0">
                Origin
              </th>
              <th className="sticky top-[115px] bg-card-light text-base dark:bg-card-dark lg:top-0">
                Message
              </th>
              <th className="sticky top-[115px] w-12 rounded-tr-lg bg-card-light text-base dark:bg-card-dark lg:top-0"></th>
            </tr>
          </thead>
          <tbody>
            {logs.length > 0 ? (
              logs.map((log) => <LogRow key={log.id} log={log} />)
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={4}
                >
                  No Logs for this team yet
                </td>
              </tr>
            )}
          </tbody>
          <tbody>
            <TableFooter colSpan={4} />
          </tbody>
        </table>
      )}
    </>
  );
};

export default LogsTab;
