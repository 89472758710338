/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import keapImg from "../../assets/images/KeapLogoTransparent.png";
import CircularActionButton from "../buttons/CircularActionButton";
import {
  faArrowsRotate,
  faCircleExclamation,
  faPenToSquare,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import IdDisplay from "./IdDisplay";
import ButtonLoader from "../loading/ButtonLoader";
import { keapApi } from "src/api";
import DisplayEditText from "./DisplayEditText";
import useModal from "src/hooks/useModal";
import EditKeapIDModal from "../modals/EditKeapIDModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import findKeapTag from "src/helpers/findKeapTag";
import LoadingSpinner from "../loading/LoadingSpinner";

interface KeapCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
  dealioSitePlanIDGlobal: number | null;
  setDashUpgradeTagInKeapGlobal: Dispatch<SetStateAction<boolean>>;
}

export interface KeapTag {
  tag: {
    id: number;
    name: string;
    category: string;
  };
  date_applied: Date;
}

export interface KeapData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dealioPlanID: number | null;
  dealioAccountID: number | null;
  sendFuseTeamID: number | null;
  sendFuseSubscriptionID: number | null;
  tags: KeapTag[];
}

export interface UpdateKeapData {
  keapID: number;
  dealioAccountID?: number | null | undefined;
  dealioPlanID?: number | null | undefined;
  sendFuseTeamID?: number | null | undefined;
  sendFuseSubscriptionID?: number | null | undefined;
}

const KeapCard = ({
  team,
  fetchTeam,
  dealioSitePlanIDGlobal,
  setDashUpgradeTagInKeapGlobal,
}: KeapCardProps) => {
  const [isLoadingKeapData, setIsLoadingKeapData] = useState<boolean>(true);

  const { openModalWith } = useModal();

  const [keapError, setKeapError] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isSavingKeapData, setIsSavingKeapData] = useState<boolean>(false);

  const [keapData, setKeapData] = useState<KeapData | null>(null);

  const [editedKeapData, setEditedKeapData] = useState<KeapData | null>(null);

  const { keapID } = team.team;

  useEffect(() => {
    setEditedKeapData(keapData);
  }, [keapData]);

  const handleInputChange = (field: keyof KeapData, value: string | number) => {
    if (editedKeapData) {
      setEditedKeapData({ ...editedKeapData, [field]: value });
    }
  };

  const fetchKeapData = async () => {
    setKeapError(false);
    setIsLoadingKeapData(true);
    if (keapID) {
      try {
        const res = await keapApi.fetchKeapData(keapID);
        setKeapData(res);
        setIsLoadingKeapData(false);
      } catch (err) {
        console.error(err);
        setKeapError(true);
        setIsLoadingKeapData(false);
      }
    }
  };

  useEffect(() => {
    fetchKeapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keapID,
    team.team.subscriptionID,
    dealioSitePlanIDGlobal,
    team.team.realeflowID,
  ]);

  useEffect(() => {
    if (keapData?.tags) {
      if (findKeapTag(keapData.tags, 179820)) {
        setDashUpgradeTagInKeapGlobal(true);
      } else {
        setDashUpgradeTagInKeapGlobal(false);
      }
    }
  }, [keapID, keapData]);

  const handleClickKeapID = () => {
    openModalWith({
      title: "Edit KeapID",
      body: <EditKeapIDModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleEditKeap = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveKeapData = async () => {
    setIsSavingKeapData(true);
    try {
      await keapApi.updateKeapData({
        keapID,
        dealioAccountID: editedKeapData?.dealioAccountID,
        dealioPlanID: editedKeapData?.dealioPlanID,
        sendFuseTeamID: editedKeapData?.sendFuseTeamID,
        sendFuseSubscriptionID: editedKeapData?.sendFuseSubscriptionID,
      });
      fetchKeapData();
      fetchTeam(); // Refresh team data if necessary
    } catch (err) {
      console.error(err);
    } finally {
      setIsSavingKeapData(false);
      setIsEditing(false);
    }
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={keapImg} alt={"Keap Logo"} className="w-16" />
          </div>
          <IdDisplay
            ID={keapID}
            label="KeapID"
            onClick={handleClickKeapID}
            error={keapError}
          />
          <div className="flex w-24 items-center justify-end gap-2">
            <CircularActionButton
              icon={faRotateRight}
              onClick={fetchKeapData}
              tooltip="Refresh Data"
              delayTooltipShow={200}
              isLoading={isLoadingKeapData}
              loadingClassName="text-[#32A732]"
            />
            <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditKeap}
            />
          </div>
        </div>
        <div className="mb-4 mt-0.5 flex w-full items-center justify-center">
          {isLoadingKeapData ? (
            <LoadingSpinner className="mt-2 h-8 w-8 text-[#32A732]" />
          ) : keapError ? (
            <div className="mt-4 flex items-center justify-center gap-2">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="h-4 w-4 text-error"
              />
              <p className="text-error">KeapID not found, please update</p>
            </div>
          ) : (
            <div className="flex w-full  gap-2">
              <div className="w-1/2">
                <DisplayEditText
                  label={"First Name"}
                  name={"firstName"}
                  value={keapData?.firstName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Email"}
                  name="email"
                  value={keapData?.email || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"SendFuseTeamID"}
                  name={"sendFuseTeamID"}
                  value={editedKeapData?.sendFuseTeamID || ""}
                  disabled={!isEditing}
                  error={keapData?.sendFuseTeamID !== team.team.id}
                  success={keapData?.sendFuseTeamID === team.team.id}
                  onChange={(e) =>
                    handleInputChange("sendFuseTeamID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"SendFuse SubscriptionID"}
                  name={"sendFuseSubscriptionID"}
                  value={editedKeapData?.sendFuseSubscriptionID || ""}
                  disabled={!isEditing}
                  error={
                    keapData?.sendFuseSubscriptionID !==
                    team.team.subscriptionID
                  }
                  success={
                    keapData?.sendFuseSubscriptionID ===
                    team.team.subscriptionID
                  }
                  onChange={(e) =>
                    handleInputChange("sendFuseSubscriptionID", e.target.value)
                  }
                />
              </div>
              <div className="w-1/2">
                <DisplayEditText
                  label={"Last Name"}
                  name="lastName"
                  value={keapData?.lastName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Phone Number"}
                  name="phoneNumber"
                  value={keapData?.phoneNumber || ""}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Dealio AccountID"}
                  name="dealioAccountID"
                  value={editedKeapData?.dealioAccountID || ""}
                  disabled={!isEditing}
                  error={keapData?.dealioAccountID !== team.team.realeflowID}
                  success={keapData?.dealioAccountID === team.team.realeflowID}
                  onChange={(e) =>
                    handleInputChange("dealioAccountID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"Dealio Site Plan ID"}
                  name="dealioPlanID"
                  value={editedKeapData?.dealioPlanID || ""}
                  disabled={!isEditing}
                  onChange={(e) =>
                    handleInputChange("dealioPlanID", e.target.value)
                  }
                  success={keapData?.dealioPlanID === dealioSitePlanIDGlobal}
                  error={keapData?.dealioPlanID !== dealioSitePlanIDGlobal}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {isEditing && (
            <button
              onClick={handleSaveKeapData}
              className="btn-sm btn w-full bg-green-600 text-white hover:bg-green-500"
            >
              {isSavingKeapData ? (
                <ButtonLoader className="h-4 w-4" fill={"#32A732"} />
              ) : (
                "Save"
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default KeapCard;
